import { getRandomInt } from '../../../global_assets/utilities.js'

const Circle = () => {
    console.log(getRandomInt)
    const radius = getRandomInt(35)
    return(
        <div
        className='bg-circle'
        style={{
            position: 'fixed',
            borderColor: '#FFFFFF',
            borderStyle: 'solid',
            borderRadius: '50%',
            backgroundColor: 'rgba(0,0,0,0)',
            width: `${radius}vh`,
            height: `${radius}vh`,
            borderWidth: `${getRandomInt(3)}px`,
            top: `${getRandomInt(100)}vh`,
            left: `${getRandomInt(100)}vw`,

        }}
        />
    )
}

export default Circle