import '../styles/socialbar.css'

import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

const SocialBar = () => {
    return (
        <div className='social-bar-layout'>
            <IconButton
            href="https://www.facebook.com/Addicted2Salt78" target="_blank">
                <FacebookIcon
                    htmlColor="#FFFFFF"
                    fontSize="large"
                />
            </IconButton>
            <IconButton href="https://www.instagram.com/jonathan_yayy/" target="_blank">
                <InstagramIcon
                    htmlColor="#FFFFFF"
                    fontSize="large"
                />
            </IconButton>
        </div>
    )
}

export default SocialBar