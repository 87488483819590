import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const StyledLinkButton = styled(Button)(({ theme }) => ({
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // Glassmorphism
    textDecoration: 'none',
    borderRadius: '15px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    border: '2px solid rgba(255,255,255,1)',
    backdropFilter: 'blur(5px)',
    boxShadow: '10px 10px 4px rgba(0,0,0,0.3)',
    color: '#ffffff',
    textTransform: 'none',
    marginTop: '4vh',
    '&:hover': {
        backgroundColor: ' rgba(255,255,255,0.2)',
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
    },
    [theme.breakpoints.down(1000)]: {
        fontSize:'1.75vh',
        height: '6%',
        width: '70%',
    },
    [theme.breakpoints.up(1000)]: {
        fontSize: 'max(15px, 0.6vw)',
        height: '50px',
        width: 'max(300px, 30%)',
    },
  }));

const LinkButton = (props) => {
    const {
        name,
        link
    } = props

    return (
        <StyledLinkButton className='button' href={link} target="_blank">
            {name}
        </StyledLinkButton>
    )
}

export default LinkButton