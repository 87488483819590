import "./styles/linkhub.css";
import pfp from "../../global_assets/pfp.jpeg";
import linksjson from "./links.json";

import Circle from "./components/Circle.jsx";
import GlassWrap from "./components/GlassWrap.jsx";

/**
 * Main code for the LinkTree styled page
 * @returns Layout for the LinkTree-style page
 */
const Linkhub = () => {
  const minCircles = 5;
  const maxCircles = 10;
  return (
    <div className="linkhub-layout">
      <div className="linkhub-background" />
      {[
        ...Array(
          Math.floor(Math.random() * (maxCircles - minCircles) + minCircles)
        ),
      ].map(() => (
        <Circle />
      ))}

      <div className="pfp-img-container">
        <img src={pfp} className="img-profile" alt="Jonathan Yeh" />
      </div>

      <div className="linkhub-name">Jonathan Yeh</div>

      <GlassWrap json={linksjson} />
    </div>
  );
};

export default Linkhub;
