/**
 * Returns a random Integer between 0 and a given maximum value
 * @param {Integer} maxVal maximum value for the returned value
 * @returns a random Integer between 0 and maxVal
 */
const getRandomInt = (maxVal) => {
return Math.floor(Math.random() * maxVal)
}

export {
    getRandomInt
}