import Linkhub from "./pages/linkhub/Linkhub";

function App() {
  return (
    <div>
      <Linkhub />
    </div>
  );
}

export default App;
