import LinkButton from "./LinkButton.jsx";
import SocialBar from "./SocialBar.jsx";

/**
 * A glassmorphism wrapper div for links to display
 * @param {JSON} json JSON file of links to display
 * @returns A glassmorphism wrapper for links
 */
const GlassWrap = (props) => {
  const { json } = props;

  return (
    <div className="links-container">
      {json.map((link) => (
        <LinkButton name={link.name} link={link.link} />
      ))}
      <SocialBar />
    </div>
  );
};

export default GlassWrap;
